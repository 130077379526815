import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeviceDetector from 'device-detector-js';
import { useWindowSize } from '../Helpers/useWindowResize';
import { limitMobilePaddings } from '../Helpers/limitMobilePaddings';
import { Header } from '../Components/Header/Header';
import { Footer } from '../Components/Footer/Footer';
//v6

function PublishedPage() {
  const { newsletterId } = useParams();
  const [htmlContent, setHtmlContent] = useState('');
  const [proPremierUser, setProPremierUser] = useState(true);
  const [isFreeUser, setIsFreeUser] = useState(false);
  const navigate = useNavigate();
  const bodyRef = useRef();

  const detector = new DeviceDetector();
  const userAgent = navigator.userAgent;
  const deviceType = detector.parse(userAgent).device.type;

  const { width } = useWindowSize();

  useEffect(() => {
    fetchNewsletterData();
  }, [newsletterId]);

  useEffect(() => {
    if (!bodyRef.current) return;

    if (width < 400 && deviceType === 'smartphone') {
      Array.from(bodyRef.current.childNodes).forEach((node) => {
        if (node.firstChild?.classList?.contains('newsletter_section')) {
          node.firstChild.style.paddingLeft = limitMobilePaddings(
            parseInt(node.firstChild.style.paddingLeft)
          );
          node.firstChild.style.paddingRight = limitMobilePaddings(
            parseInt(node.firstChild.style.paddingRight)
          );
        }
      });
    }
  }, [htmlContent, width]);

  async function fetchNewsletterData() {
    const dataResponse = await fetch(
      `https://${process.env.REACT_APP_API_SERVER}/api/${process.env.REACT_APP_API_VERSION}/presets/${newsletterId}/public_show`,
      { cache: 'no-cache' }
    );

    if (dataResponse.published === false || dataResponse.status === 404) {
      navigate('/');
      return;
    }
    const dataJson = await dataResponse.json();
    const userCurrentPlan = dataJson.workspace_current_plan;

    if (userCurrentPlan.includes('Free')) {
      setIsFreeUser(true);
    }

    const contentResponse = await fetch(
      `${process.env.REACT_APP_SECURE_URL_LINK}/presets/${dataJson.s3_key}.html`,
      { cache: 'no-cache' }
    );
    const htmlContentResult = await contentResponse.text();
    setHtmlContent(htmlContentResult);
    setProPremierUser(
      userCurrentPlan.includes('Pro') ||
        userCurrentPlan.includes('Premier') ||
        userCurrentPlan.includes('Elite')
    );
  }

  const handleVideoComponent = (componentContainer) => {
    const parent = componentContainer.parentElement;
    const url = parent.dataset.videoUrl;

    // Uploaded video
    if (parent.className === 'video_uploaded_wrapper') {
      parent.innerHTML = `
        <video controls autoPlay>
          <source src="${url}" type='video/mp4' />
        </video>
      `;
    } else {
      // Youtube Video
      parent.innerHTML = `
        <iframe
          title={'YouTube video'}
          src=${url}
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
          allowFullScreen
        />
      `;
    }
  };

  const handleButtonComponent = (componentContainer) => {
    const url = componentContainer.dataset.buttonUrl;
    const hasLinkWrapper = !!componentContainer.closest(
      '.button_component_link'
    );

    if (url !== 'no_link' && !hasLinkWrapper) {
      const handleLinkClick = (event) => {
        event.stopPropagation();
        link.removeEventListener('click', handleLinkClick);
        link.remove();
      };
      const link = document.createElement('a');
      link.href = !/^https?:\/\//i.test(url) ? `https://${url}` : url;
      link.setAttribute('target', '_blank');
      link.addEventListener('click', handleLinkClick);
      link.click();
    }
  };

  const handleBodyClick = (event) => {
    const { target } = event;
    const componentContainer = target.closest(
      '.thumbnail_youtube_container, .thumbnail_container, .button_component_body'
    );
    switch (componentContainer?.className) {
      case 'thumbnail_youtube_container':
      case 'thumbnail_container':
        handleVideoComponent(componentContainer);
        break;
      case 'button_component_body':
        handleButtonComponent(componentContainer);
        break;
    }
  };

  return (
    <div className="publish_page_wrapper">
      {!proPremierUser && <Header />}
      <div className="publish_page_body" onClick={handleBodyClick}>
        <div
          className={`${isFreeUser ? 'publish_page_watermark' : ''}`}
          ref={bodyRef}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
      </div>
      <Footer />
    </div>
  );
}

export default PublishedPage;
