import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import packageJson from '../package.json';
import * as Sentry from '@sentry/react';

const version = packageJson.version;

const isProd = window.location.host === 'view.gogipper.com';

isProd &&
  Sentry.init({
    dsn: 'https://7ba62f83dcabc091a0bd137f4e157e0b@o4505885323231232.ingest.us.sentry.io/4509016356814848',
    integrations: [Sentry.browserTracingIntegration()],
    enableTracing: false,
    tracesSampleRate: 0,
    maxBreadcrumbs: 10,
    release: version,
  });

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <Router>
      <App />
    </Router>
  </StrictMode>
);
