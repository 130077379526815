import './Footer.css';
import usePendoTrackEvent from '../../Helpers/usePendoTrackEvent';

export const Footer = () => {
  const trackEvent = usePendoTrackEvent();

  const openInNewTab = () => {
    const link = document.createElement('a');
    link.href =
      'https://account.gogipper.com/sign_up?utm_campaign=gipper_newsletters&utm_source=referring_link&utm_medium=newsletter_link&utm_term=evergreen&utm_content=trial';
    link.setAttribute('target', '_blank');
    link.click();
    link.remove();
  };

  const handleCreateNewsletter = () => {
    trackEvent('Create a newsletter button was clicked');
    openInNewTab();
  };
  return (
    <div className="publish_page_footer">
      <div className="publish_page_footer_wrapper">
        <a target="_blank" href="https://www.gipper.com/" className="logo_link">
          <span>Created with</span>
          <img
            className="gipper_logo"
            src="https://dzfa1uifb0sb6.cloudfront.net/stock_images/the_gipper_logo_gn.png"
            alt="gipper logo"
          />
        </a>
        <p>
          Keep your community informed with regular updates and announcements by
          using Gipper Newsletters.
        </p>
        <button
          className="create_newsletter_link green_solid_button"
          onClick={handleCreateNewsletter}
        >
          Try Gipper
        </button>
      </div>
    </div>
  );
};
