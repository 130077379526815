function EmptyPage() {
  return (
    <div className="empty_page_wrapper">
      <div className="empty_page_icon"></div>
      <p className="empty_page_title">This newsletter no longer exists</p>
      <p>If you believe this is a mistake, please contact the sender.</p>
    </div>
  );
}

export default EmptyPage;
