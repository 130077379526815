
import { useParams } from 'react-router';
import { useState } from 'react';

const MAX_RETRIES = 3;

function usePendoTrackEvent() {
  const params = useParams();
  const newsletterId = Number(params.newsletterId);
  const [retryCount, setRetryCount] = useState(0);

  const trackEvent = (eventName, properties = {}) => {
    const trackData = {
      template_id: newsletterId,
      ...properties,
    };

    if (window?.pendo?.isReady?.()) {
      setRetryCount(0); // Reset retry count if success

      // ----------------------------------------------------------------------------------
      //! ! ! ! FOR TEST PURPOSES, UNCOMMENT IF NEEDED ! ! ! !
      // console.log(eventName, trackData);
      // alert(`${eventName}\n${JSON.stringify(trackData, undefined, 2)}`);
      //! ! ! ! FOR TEST PURPOSES, UNCOMMENT IF NEEDED ! ! ! !
      // ----------------------------------------------------------------------------------

      return window.pendo.track(eventName, trackData);
    } else {
      if (retryCount < MAX_RETRIES) {
        setRetryCount((prevCount) => prevCount + 1);
        setTimeout(() => {
          if (window?.pendo?.isReady?.()) {
            trackEvent(eventName, properties);
          }
        }, 500);
      } else {
        console.warn('Failed to track Pendo event after multiple retries');
      }
    }
  };

  return trackEvent;
}

export default usePendoTrackEvent;
