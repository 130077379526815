// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create_newsletter_link {
  width: 168px;
  margin-top: 20px;
  font-family: var(--encode_sans);
}

.publish_page_footer {
  padding: 40px 20px;
  border-top: 1px solid var(--main_color_78);
  font-family: var(--encode_sans);
}

@media (max-width: 700px) {
  .publish_page_footer {
    padding: 20px 20px;
  }
  .publish_page_footer_wrapper {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .create_newsletter_link {
    width: 113px;
    height: 36px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;EAClB,0CAA0C;EAC1C,+BAA+B;AACjC;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,uBAAuB;EACzB;;EAEA;IACE,YAAY;IACZ,YAAY;EACd;AACF","sourcesContent":[".create_newsletter_link {\n  width: 168px;\n  margin-top: 20px;\n  font-family: var(--encode_sans);\n}\n\n.publish_page_footer {\n  padding: 40px 20px;\n  border-top: 1px solid var(--main_color_78);\n  font-family: var(--encode_sans);\n}\n\n@media (max-width: 700px) {\n  .publish_page_footer {\n    padding: 20px 20px;\n  }\n  .publish_page_footer_wrapper {\n    text-align: center;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n  }\n\n  .create_newsletter_link {\n    width: 113px;\n    height: 36px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
