// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* production */

img,
a {
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}

#root {
  color: #2a2a3c;
  height: 100%;
  background-color: #fff;
}

button,
a {
  line-height: initial;
}

.thumbnail_youtube_container {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;;EAEE,yBAAyB;EACzB,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,sBAAsB;AACxB;;AAEA;;EAEE,oBAAoB;AACtB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["/* production */\n\nimg,\na {\n  -webkit-user-select: none;\n  -webkit-user-drag: none;\n  -webkit-app-region: no-drag;\n}\n\n#root {\n  color: #2a2a3c;\n  height: 100%;\n  background-color: #fff;\n}\n\nbutton,\na {\n  line-height: initial;\n}\n\n.thumbnail_youtube_container {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
