import './Header.css';
import usePendoTrackEvent from '../../Helpers/usePendoTrackEvent';

export const Header = () => {
  const trackEvent = usePendoTrackEvent();

  const openInNewTab = () => {
    const link = document.createElement('a');
    link.href =
      'https://account.gogipper.com/sign_up?utm_campaign=gipper_newsletters&utm_source=referring_link&utm_medium=newsletter_link&utm_term=evergreen&utm_content=trial';
    link.setAttribute('target', '_blank');
    link.click();
    link.remove();
  };

  const handleCreateNewsletter = () => {
    trackEvent('Create a newsletter button was clicked');
    openInNewTab();
  };

  return (
    <div className="gipper_header">
      <a
        target="_blank"
        href="https://www.gipper.com/"
        className="created_with_btn"
      >
        Created with
        <img
          src="https://dzfa1uifb0sb6.cloudfront.net/stock_images/the_gipper_logo_gn.png"
          className="created_with_bt--logo"
          alt="gipper logo"
        />
      </a>

      <button
        className="create_newsletter_btn green_solid_button"
        onClick={handleCreateNewsletter}
      >
        Try Gipper
      </button>
    </div>
  );
};
