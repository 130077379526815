// import { useEffect } from 'react';
import './App.css';
import { useRoutes } from 'react-router-dom';
import { routes } from './Routes';
function App() {
  const routing = useRoutes(routes());

  // useEffect(() => {
  //   const initializePendo = () => {
  //     if (window.pendo.initialize) {
  //       window.pendo.initialize({
  //         visitor: {},
  //       });
  //       clearInterval(pendoCheckInterval);
  //     }
  //   };

  //   const pendoCheckInterval = setInterval(initializePendo, 1000);
  //   return () => clearInterval(pendoCheckInterval);
  // }, []);

  return routing;
}

export default App;
