import { Navigate } from 'react-router-dom';
import EmptyPage from './App/Pages/EmptyPage';
import PublishedPage from './App/Pages/PublishedPage';

const newsletterLayout = () => {
  return [
    { path: '/', element: <EmptyPage /> },
    { path: '/page/:newsletterId', element: <PublishedPage /> },
    { path: '*', element: <Navigate to="/" /> },
  ];
};

const routes = () => {
  let layout = newsletterLayout();

  return [...layout];
};

export { routes };
