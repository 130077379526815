// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gipper_header {
  display: flex;
  justify-content: space-between;
  padding: 10px 45px;
  font-size: 14px;
  font-family: var(--encode_sans);
  background-color: var(--white_color_100);
}

.created_with_btn {
  color: var(--main_color_10);
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 10px;
}

.create_newsletter_btn {
  font-family: var(--encode_sans);
}

.created_with_bt--logo {
  width: 111px;
}

@media (max-width: 700px) {
  .gipper_header {
    padding: 12px 16px;
  }

  .create_newsletter_btn {
    width: 102px;
    height: 32px;
    font-size: 12px;
  }

  .created_with_btn {
    font-size: 10px;
  }

  .created_with_bt--logo {
    width: 78px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App/Components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,eAAe;EACf,+BAA+B;EAC/B,wCAAwC;AAC1C;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,SAAS;AACX;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".gipper_header {\n  display: flex;\n  justify-content: space-between;\n  padding: 10px 45px;\n  font-size: 14px;\n  font-family: var(--encode_sans);\n  background-color: var(--white_color_100);\n}\n\n.created_with_btn {\n  color: var(--main_color_10);\n  position: relative;\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  gap: 10px;\n}\n\n.create_newsletter_btn {\n  font-family: var(--encode_sans);\n}\n\n.created_with_bt--logo {\n  width: 111px;\n}\n\n@media (max-width: 700px) {\n  .gipper_header {\n    padding: 12px 16px;\n  }\n\n  .create_newsletter_btn {\n    width: 102px;\n    height: 32px;\n    font-size: 12px;\n  }\n\n  .created_with_btn {\n    font-size: 10px;\n  }\n\n  .created_with_bt--logo {\n    width: 78px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
